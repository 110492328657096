import React, { PureComponent } from 'react'
import _ from "lodash"
import { navigate } from 'gatsby'
import { match } from '@reach/router/lib/utils';

import Header from "./header"


export default class ConfirmationComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }
  componentDidMount() {
    if (this.props.pageContext.matchPath) {
      const result = match(this.props.pageContext.matchPath, this.props.location.pathname);
      if (result && result.params) {
        if (result && result.params.id !==_.get(this.props, 'location.state.data.orderid', '')) {
          navigate(`/`);
        }
      } else {
        navigate(`/`);
      }
    } else {
      navigate(`/`);
    }
  }

  render() {
    const { location } = this.props
    return (
      <div>
        <Header {...this.props}/>
        <div className="order-wrppers">
          <div className="order-wrppers-inner">
            <h5>Order Submitted!</h5>
            <p>Order Number: <span className="font-500">{_.get(this.props, 'location.state.data.orderid', '')}</span></p>
            <p>Thank you for choosing Curbside!  You will receive a summary of your order details via email shortly.</p>
            <p>Please note that your card will not be charged until a Curbside team member approves your order, after which you will receive an additional email confirmation and receipt.  If we are unable to approve the order with the information provided, you will be contacted by a Curbside team member to discuss any necessary adjustments required.</p>
            <button
              className="fill-fullwidth-yellow"
              onClick={() => navigate('/')}
            >
              Take me home
            </button>
          </div>
        </div>
      </div>
    )
  }
}
