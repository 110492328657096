import React from "react"

import Layout from "../components/layout"
import Confirmation from "../components/marketing/confirmation"
import SEO from "../components/seo"

const ConfirmationPage = (props) => (
  <Layout>
    <SEO title="Confirmation" />
    <Confirmation {...props}/>
  </Layout>
)

export default ConfirmationPage
